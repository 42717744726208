import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useStyletron } from 'baseui';
import { Spinner } from 'baseui/spinner';
import { useQuery } from '@apollo/client';
import { Router } from '@reach/router';
import { ToasterContainer, PLACEMENT, toaster } from 'baseui/toast';
import { editorBtnOptions } from '../../data/cardBuilderOptions';
import { debounce, throttle } from 'lodash';
import { Skeleton } from 'baseui/skeleton'
import {
  SnackbarProvider,
} from 'baseui/snackbar';
import {
  organization,
  app_content_scope_groups_org,
  app_content_scopes_org,
  category_app_content_scopes,
  deck_categories,
  card_decks,
  quiz_question_quiz_decks_org,
  getJWPlayerData,
  getCloudinarySettings,
  getFilestackData,
  libraries,
  getTags
} from '../quries';
import {
  setCategory,
  setDecks,
  setCards,
  setOrg,
  setOrgSettings,
  setGroups,
  setChannels,
  setQuestionCards,
  setIsLoading,
  setJWPlayerData,
  setFilestackData,
  setCloudinarySettings,
  setLibraryData,
  setOrganizationTags,
  setOrganizationProducts,
  setOrganizationProductBreakthroughIntegration,
  setOrganizationProductDataCollectionIntegration,
  setOrganizationProductEngageIntegration,
  setOrganizationProductCRMIntegration
} from '../redux/actions/appBasicControls';

import SubscribeCategory from '../components/subscribe/subscribe-category';
import SubscribeDeck from '../components/subscribe/subscribe-deck';
import SubscribeCard from '../components/subscribe/subscribe-card';
import SubscribeChannel from '../components/subscribe/subscribe-channel';
import SubscribeGroup from '../components/subscribe/subscribe-group';

import Layout from '../components/layout/index';
import Library from '../components/library'
import Builder from '../components/builder';
import BuilderQuiz from '../components/builder/quiz';
import Connect from '../components/connect/index';
import AddCommunication from '../components/connect/add-communication';
import Dashboard from '../components/dashboard/index';
import Feedback from '../components/feedback/index';
import Settings from '../components/settings/index';
import AdminComponent from '../components/admin/index';
import Channels from '../components/channel/index';
import Groups from '../components/groups/index';
import Wrap from '../components/wrap';
import Users from '../components/users/index';
import Profile from '../components/profile/index';
import TourCard from '../components/tour/TourCard';
import store from '../redux/store';
import TravelPass from '../components/travel-pass/index';
import Templates from '../components/templates';
import Stash from '../components/stash';
import { getToken } from '../auth';
import { getServerUrl } from '../apollo/client';
import { organization_breakthroughs_by_org_id, organization_data_collections_by_org_id, organization_engage_by_org_id, organization_marketings_by_org_id } from '../query/platform_integrations';

const dispatch = store.dispatch;
const dispatchDebounced = debounce(dispatch, 2000);

const App = ({ channels, organizationData, dispatch, sub_domain_id, isLoading, category, decks, cards, isApiInProgress, isTourPopEnabled, showAlert, ...rest }) => {
  const ORGANIZATION_ID = rest['*'].split('/')[0];
  // BASE UI ENGINE SETUP
  const [options, setOptions] = React.useState({
    engine: null,
  });
  const [popup, setPopup] = React.useState(false);
  const [css, theme] = useStyletron();

  var engine = options.engine;


  React.useEffect(() => {
    // let isTourPopupShown = () => {
    //   return new Promise((resolve, reject) => {
    //       let v = localStorage.getItem('isTourPopupShown');
    //       return resolve(v);
    //   })
    // }

    // isTourPopupShown()
    //    .then(function(v) { 
    //      setPopup(v);
    //    })
    //    .catch(function(err) {
    //     setPopup(false)
    //    });
  }, []);


  React.useEffect(() => {

    import('styletron-engine-atomic').then(styletron => {
      const clientEngine = new styletron.Client();
      setOptions({
        ...options,
        engine: clientEngine,
      });
    });
  }, []);
  // END => BASE UI ENGINE SETUP


  // ON RELOAD ALERT FOR USERS IF CARD DATA SAVING IN PROGRESS
  React.useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  });
  const alertUser = e => {
    if (!isApiInProgress) {
      return;
    }
    e.preventDefault();
    var confirmationMessage = "Are you sure you want to refresh this page?  Data is currently being saved for this card and all changes will be lost.";
    (e || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  };
  // END => ON RELOAD ALERT FOR USERS IF CARD DATA SAVING IN PROGRESS

  const {
    data: organization_data,
    loading: isOrganizationLoading,
    refetch,
  } = useQuery(organization(ORGANIZATION_ID), {
    fetchPolicy: organizationData ? 'cache-only' : 'network-only',
  });

  // LOADING INITIAL API DATA
  // LOAD Groups
  const {
    data: app_content_scope_groups_org_data,
    subscribeToMore: subscribeToMoreGroup,
    loading: isAppContentScopeGroupsOrgDataLoading,
    refetch: refreshAppContentScopeGroups
  } = useQuery(app_content_scope_groups_org(ORGANIZATION_ID));
  // LOAD CHANNELS
  const {
    data: app_content_scopes_org_data,
    subscribeToMore: subscribeToMoreChannel,
    loading: isAppContentScopesOrgDataLoading,
    refetch: refreshAppContentScopes
  } = useQuery(app_content_scopes_org(ORGANIZATION_ID));
  // LOAD CATEGORY
  const {
    data: category_app_content_scopes_data,
    subscribeToMore: subscribeToMoreCategory,
    loading: isCategoryAppContentScopesDataLoading,
    refetch: refreshCategories
  } = useQuery(category_app_content_scopes(ORGANIZATION_ID));
  // LOAD DECKS
  const {
    data: deck_categories_data,
    subscribeToMore: subscribeToMoreDeck,
    loading: isDeckCategoriesDataLoading,
    refetch: refreshDecks1
  } = useQuery(
    deck_categories(ORGANIZATION_ID),
    {
      variables: { category_ids: category.map((el) => el.category_id) },
      skip: category.length == 0 ? true : false
    }
  );
  // LOAD ORGANIZATION TAGS
  const {
    data: org_tags_data,
    refetch: refreshOrgTags
  } = useQuery(
    getTags(ORGANIZATION_ID)
  );

  const refreshDecks = () => {
    console.log('refetchign decks');
    refreshDecks1();
  }
  // LOAD CARDS
  const {
    data: card_decks_data,
    subscribeToMore: subscribeToMoreCard,
    loading: isCardDecksDataLoading,
    refetch: refreshCards
  } = useQuery(card_decks(ORGANIZATION_ID));
  // LOAD QUIZ QUESTIONS
  const {
    data: quiz_question_quiz_decks_org_data,
    loading: isQuizQuestionQuizDecksDataLoading,
    refetch: refreshQuizQuestions1
  } = useQuery(quiz_question_quiz_decks_org(ORGANIZATION_ID), {
    variables: { deck_ids: decks.map((el) => el.deck_id) },
    skip: decks.length == 0 ? true : false
  });

  const refreshQuizQuestions = () => {
    console.log('pull quiz questions');
    refreshQuizQuestions1();
  }

  const { data: jwPlayerData } = useQuery(getJWPlayerData(ORGANIZATION_ID))
  const { data: cloudinarySettings } = useQuery(getCloudinarySettings(ORGANIZATION_ID))
  const { data: fiilestackSettings } = useQuery(getFilestackData(ORGANIZATION_ID))
  const { data: libraryData } = useQuery(libraries(ORGANIZATION_ID))

  const { data: organizationBreakthrough } = useQuery(organization_breakthroughs_by_org_id, {
    variables: {organization_id: ORGANIZATION_ID}
  })

  const { data: organizationEngage } = useQuery(organization_engage_by_org_id, {
    variables: {organization_id: ORGANIZATION_ID}
  })

  const { data:  organizationDataCollections} = useQuery(organization_data_collections_by_org_id, {
    variables: {organization_id: ORGANIZATION_ID}
  })

  const { data:  organizationMarketing} = useQuery(organization_marketings_by_org_id, {
    variables: {organization_id: ORGANIZATION_ID}
  })


  React.useEffect(() => {
    const {organization_breakthroughs_by_org_id: d1} = organizationBreakthrough || {};
    if (!d1 || d1?.length <=0) {
      dispatch(setOrganizationProductBreakthroughIntegration(
        {
          is_breakthrough_integration_button_active: false
        }
      ))
      return;  
    }
      dispatch(setOrganizationProductBreakthroughIntegration(
        {
          ...(d1[0] || {}),
          is_breakthrough_integration_button_active: d1[0]?.is_button_active || false
        }
      ))
  },[organizationBreakthrough])

  React.useEffect(() => {
    const {organization_engage_by_org_id: d1} = organizationEngage || {};
    if (!d1 || d1?.length <=0) {
      dispatch(setOrganizationProductEngageIntegration(
        {
          is_engage_button_active: false
        }
      ))
      return;  
    }
    dispatch(setOrganizationProductEngageIntegration(
      {
        ...(d1[0] || {}),
        is_engage_button_active: d1[0]?.is_button_active || false
      }
    ))
},[organizationEngage])

React.useEffect(() => {
  const {organization_data_collections_by_org_id: d1} = organizationDataCollections || {};
  if (!d1 || d1?.length <=0) {
    dispatch(setOrganizationProductDataCollectionIntegration(
      {
        is_data_collection_button_active: false
      }
    ))
    return;  
  }
  dispatch(setOrganizationProductDataCollectionIntegration(
    {
      ...(d1[0] || {}),
      is_data_collection_button_active: d1[0]?.is_button_active || false
    }
  ))
},[organizationDataCollections])

React.useEffect(() => {
  const {organization_marketings_by_org_id: d1} = organizationMarketing || {};

  if (!d1 || d1?.length <=0) {
    dispatch(setOrganizationProductCRMIntegration(
      {
        is_crm_integration_button_active: false
      }
    ))
    return;  
  }

  dispatch(setOrganizationProductCRMIntegration(
    {
      ...(d1[0] || {}),
      is_crm_integration_button_active: d1[0]?.is_button_active || false
    }
  ))
},[organizationMarketing])

  // SET LOADED DATA TO REDUX
  // SET ORGANIZATION
  React.useEffect(() => {
    // organization_data && dispatch(setOrg(organization_data.organization));
    console.log('organization_data ------>', organization_data)
    if (organization_data) {
      
      dispatch(setOrganizationProducts({
        organization_id: organization_data?.organization?.id,
        organization_name: organization_data?.organization?.name,
        admin_url: window.location.origin,
        api_url: getServerUrl(),
        // engage_integration: {
        //   id: '1',
        //   api_key: 'DurE1fWZaUc8Ni2mlhLpCnQVHv7zbRjkLm',
        //   api_secret: '',
        //   api_url: 'https://sea-turtle-app-6v8jp.ondigitalocean.app/',
        //   // app_url: 'https://sea-turtle-app-6v8jp.ondigitalocean.app/',
        //   app_url: 'http://localhost:8081',
        //   is_engage_button_active: true
        // },
        // data_collection_integration: {
        //   id: '2',
        //   api_key: 'zdfghjdfghjk',
        //   api_secret: '',
        //   api_url: 'https://breakthrough-ui-table-cms.netlify.app/',
        //   app_url: 'https://breakthrough-ui-table-cms.netlify.app/',
        //   // app_url: 'http://localhost:8080',
        //   is_data_collection_button_active: true
        // },
        // breakthrough_integration: {
        //   id: '3',
        //   api_key: '',
        //   api_secret: '',
        //   api_url: 'https://cardware-breakthrough.netlify.app/builder',
        //   app_url: 'https://cardware-breakthrough.netlify.app',
        //   // app_url: 'http://localhost:8080',
        //   is_breakthrough_integration_button_active: true
        // },
        // crm_integration: {
        //   id: '4',
        //   api_key: '',
        //   api_secret: '',
        //   api_url: 'https://cardware-user-crm.netlify.app',
        //   app_url: 'https://cardware-user-crm.netlify.app',
        //   is_crm_integration_button_active: true
        // }
      }))
      dispatch(setOrg(organization_data.organization));
      dispatch(setOrgSettings(organization_data.organization_settings));
    }
  }, [organization_data]);

  React.useEffect(() => {
    jwPlayerData && dispatch(setJWPlayerData(jwPlayerData));
  }, [jwPlayerData]);
  React.useEffect(() => {
    fiilestackSettings && dispatch(setFilestackData(fiilestackSettings));
  }, [fiilestackSettings]);
  React.useEffect(() => {
    cloudinarySettings && dispatch(setCloudinarySettings(cloudinarySettings));
  }, [cloudinarySettings]);
  React.useEffect(() => {
    libraryData && dispatch(setLibraryData(libraryData));
  }, [libraryData]);
  // SET GROUPS
  React.useEffect(() => {
    app_content_scope_groups_org_data &&
      dispatch(
        setGroups(
          app_content_scope_groups_org_data.app_content_scope_groups_org || []
        )
      );
  }, [app_content_scope_groups_org_data]);
  // SET CHANNELS
  React.useEffect(() => {
    channels.length == 0 && app_content_scopes_org_data &&
      dispatch(
        setChannels(app_content_scopes_org_data.app_content_scopes_org || [])
      );
  }, [app_content_scopes_org_data]);
  // SET CATEGORY
  React.useEffect(() => {
    category_app_content_scopes_data &&
      dispatch(
        setCategory(
          category_app_content_scopes_data.category_app_content_scopes || []
        )
      );
  }, [category_app_content_scopes_data]);
  // SET DECKS
  React.useEffect(() => {
    if (deck_categories_data) {
      deck_categories_data && dispatch(setDecks(deck_categories_data.deck_categories || []));
    }
  }, [deck_categories_data]);
  // SET CARDS
  React.useEffect(() => {
    // LOAD CARDS
    if (card_decks_data) {
      if (cards.length > 0) dispatchDebounced(setCards(card_decks_data.card_decks || []));
      else dispatch(setCards(card_decks_data.card_decks || []));
    }
  }, [card_decks_data]);
  // SET QUIZ QUESTIONS
  React.useEffect(() => {
    // if (!isLoading) {
    //   dispatch(setIsLoading(true))
    // }
    quiz_question_quiz_decks_org_data && dispatch(setQuestionCards(quiz_question_quiz_decks_org_data.quiz_question_quiz_decks_by_deck_ids || []));
  }, [quiz_question_quiz_decks_org_data]);

  React.useEffect(() => {
    org_tags_data &&
      dispatch(
        setOrganizationTags(
          org_tags_data.tags_org || []
        )
      );
  }, [org_tags_data]);

  const isLoadingApiData = isOrganizationLoading || isAppContentScopeGroupsOrgDataLoading || isAppContentScopesOrgDataLoading;
  const isOtherDataLoading = isCategoryAppContentScopesDataLoading || isDeckCategoriesDataLoading || isCardDecksDataLoading;
  console.log('isLoadingApiData', isLoadingApiData, isOtherDataLoading);
  // Mini Loader display at the top right to show if any background loading in progress
  const [isMiniLoaderActive, setIsMiniLoaderActive] = React.useState(false);
  const showMiniLoader = () => setIsMiniLoaderActive(true);
  const hideMiniLoader = () => setIsMiniLoaderActive(false);

  React.useEffect(() => {
    if (isQuizQuestionQuizDecksDataLoading) showMiniLoader();
    else hideMiniLoader();
  }, []);

  const ROUTES_COMMON_PROPS = {
    showMiniLoader,
    hideMiniLoader,
    CXL_ORGANIZATION_ID: ORGANIZATION_ID,
  };

  React.useEffect(() => {
    if (!showAlert.pushed_at) return;
    toaster.clear();
    if (showAlert.error) {
      toaster.negative(showAlert.msg || 'Error', {
        overrides: {
          InnerContainer: {
            style: { width: '100%' },
          },
        },
      });
    } else {
      toaster.positive(showAlert.msg || 'Success', {
        overrides: {
          InnerContainer: {
            style: { width: '100%' },
          },
        },
      });
    }
    setTimeout(() => {
      toaster.clear();
    }, 3000);
  }, [showAlert])

  if (!engine) return null;

  // console.log('isTourPopEnabled', isTourPopEnabled)
  return (
    <SnackbarProvider placement='bottom'>
      <Layout orgId={ORGANIZATION_ID} isMiniLoaderActive={isMiniLoaderActive || isOtherDataLoading || isApiInProgress}>
        {(isLoadingApiData || isOtherDataLoading) ? (
          // <div
          //   className={css({
          //     position: 'fixed',
          //     left: 0,
          //     top: 0,
          //     width: '100%',
          //     height: '100%',
          //     display: 'flex',
          //     justifyContent: 'center',
          //     alignItems: 'center',
          //   })}
          // >
          //   <Spinner size={96} />
          // </div>
          <div style={{ width: '100%', marginLeft: '2rem', marginTop: '1rem' }}>
            {new Array(2).fill('').map(item => (
              <Skeleton
                width="30%"
                height="40px"
                animation
                overrides={{
                  Root: {
                    style: {
                      marginBottom: '15px',
                    },
                  },
                }}
              />))}
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '2rem' }}>
              {new Array(2).fill('').map(item => <Skeleton
                width="250px"
                height="60px"
                animation
                overrides={{
                  Root: {
                    style: {
                      marginRight: '10px',
                      borderRadius: '48px'
                    },
                  },
                }}
              />)}
            </div>
            {new Array(3).fill('').map(item => (
              <>
                <Skeleton
                  width="30%"
                  height="30px"
                  animation
                  overrides={{
                    Root: {
                      style: {
                        marginBottom: '1rem',
                        marginTop: '1rem'
                      },
                    },
                  }}
                />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {new Array(7).fill('').map(item => <Skeleton
                    width="182px"
                    height="251px"
                    animation
                    overrides={{
                      Root: {
                        style: {
                          marginRight: '25px'
                        },
                      },
                    }}
                  />)}
                </div>
              </>
            )
            )}
          </div>
        ) : (
          <>
            {app_content_scope_groups_org_data && (
              <SubscribeGroup
                orgId={ORGANIZATION_ID}
                subscribeToMore={subscribeToMoreGroup}
              ></SubscribeGroup>
            )}
            {app_content_scopes_org_data && (
              <SubscribeChannel
                orgId={ORGANIZATION_ID}
                subscribeToMore={subscribeToMoreChannel}
              ></SubscribeChannel>
            )}
            {category_app_content_scopes_data && (
              <SubscribeCategory
                orgId={ORGANIZATION_ID}
                subscribeToMore={subscribeToMoreCategory}
              ></SubscribeCategory>
            )}
            {deck_categories_data && (
              <SubscribeDeck
                orgId={ORGANIZATION_ID}
                subscribeToMore={subscribeToMoreDeck}
              ></SubscribeDeck>
            )}
            {card_decks_data && (
              <SubscribeCard
                orgId={ORGANIZATION_ID}
                subscribeToMore={subscribeToMoreCard}
              ></SubscribeCard>
            )}
            {/* <Channels organization_id={orgId}></Channels> */}
            <ToasterContainer placement={PLACEMENT.bottomRight} overrides={{
              Root: {
                style: {
                  zIndex: 100
                }
              }
            }}
              autoHideDuration={3000}
            />
            <Router
              className={css({
                height: '100%'
              })}
            >
              {/* <Wrap path="/" organization_id={orgId}></Wrap> */}
              <Groups {...ROUTES_COMMON_PROPS} path={`/app/:organization_id/groups/*`}></Groups>
              <Channels {...ROUTES_COMMON_PROPS} isDataLoading={isAppContentScopesOrgDataLoading || isAppContentScopeGroupsOrgDataLoading}
                path="/app/:organization_id/groups/:groupId/channels/*" defaultGroupId={null} refreshCategories={refreshCategories}
                refreshDecks={refreshDecks} refreshCards={refreshCards} refreshQuizQuestions={refreshQuizQuestions}
                refreshAppContentScopes={refreshAppContentScopes} refreshAppContentScopeGroups={refreshAppContentScopeGroups}
                refreshOrgTags={refreshOrgTags}
              ></Channels>
              <Channels {...ROUTES_COMMON_PROPS} isDataLoading={isAppContentScopesOrgDataLoading || isAppContentScopeGroupsOrgDataLoading}
                path="/app/:organization_id/groups/:groupId/channels/:channelId/deck/:deckId" defaultGroupId={null}
                refreshCategories={refreshCategories} refreshDecks={refreshDecks} refreshCards={refreshCards}
                refreshQuizQuestions={refreshQuizQuestions} refreshAppContentScopes={refreshAppContentScopes}
                refreshAppContentScopeGroups={refreshAppContentScopeGroups}
                refreshOrgTags={refreshOrgTags}
              ></Channels>
              <Users {...ROUTES_COMMON_PROPS} path="/app/:organization_id/basic"></Users>
              <TravelPass {...ROUTES_COMMON_PROPS} path="/app/:organization_id/reward/:tab_name" toaster={toaster}></TravelPass>
              <AdminComponent {...ROUTES_COMMON_PROPS} path="/app/:organization_id/admins"></AdminComponent>
              <Builder
                {...ROUTES_COMMON_PROPS}
                path="/app/:organization_id/groups/:groupId/channels/:channelId/deck/:deckId/card/:cardId"
                options={editorBtnOptions}
                toaster={toaster}
              ></Builder>
              <Builder
                {...ROUTES_COMMON_PROPS}
                path="/app/:organization_id/groups/:groupId/channels/:channelId/cardStack/:cardStackId/card/:cardId"
                options={editorBtnOptions}
                toaster={toaster}
                isCardStack={true}
              ></Builder>
              <Builder
                {...ROUTES_COMMON_PROPS}
                path="/app/:organization_id/cards/card/:cardId"
                options={editorBtnOptions}
                toaster={toaster}
                isAllCards={true}
              ></Builder>
              <Builder
                {...ROUTES_COMMON_PROPS}
                path="/app/:organization_id/templates/deck/:deckId/card/:cardId"
                options={editorBtnOptions}
                toaster={toaster}
                isTemplate={true}
              ></Builder>
              <Builder
                {...ROUTES_COMMON_PROPS}
                path="/app/:organization_id/templates/cardStack/:cardStackId/card/:cardId"
                options={editorBtnOptions}
                toaster={toaster}
                isCardStack={true}
                isTemplate={true}
              ></Builder>
              <BuilderQuiz
                {...ROUTES_COMMON_PROPS}
                path="/app/:organization_id/groups/:groupId/channels/:channelId/deck/:deckId/card/:cardId/quiz"
                options={editorBtnOptions}
                toaster={toaster}
              ></BuilderQuiz>
              <BuilderQuiz
                {...ROUTES_COMMON_PROPS}
                path="/app/:organization_id/templates/deck/:deckId/card/:cardId/quiz"
                options={editorBtnOptions}
                toaster={toaster}
                isTemplate={true}
              ></BuilderQuiz>
              <Connect {...ROUTES_COMMON_PROPS} path="/app/:organization_id/connect"></Connect>
              <AddCommunication {...ROUTES_COMMON_PROPS} path="/app/:organization_id/connect/add"></AddCommunication>
              <Dashboard {...ROUTES_COMMON_PROPS} path="/app/:organization_id/dashboard"></Dashboard>
              <Stash {...ROUTES_COMMON_PROPS} path="/app/:organization_id/stash" refetch={refetch}></Stash>
              <Feedback {...ROUTES_COMMON_PROPS} path="/app/:organization_id/feedback"></Feedback>
              <Library {...ROUTES_COMMON_PROPS} path="/app/:organization_id/library"></Library>
              <Settings
                {...ROUTES_COMMON_PROPS}
                path="/app/:organization_id/settings"
                refetch={refetch}
                toaster={toaster}
              ></Settings>
              <Profile {...ROUTES_COMMON_PROPS} path="/app/:organization_id/profile/*"></Profile>
              <Templates
                {...ROUTES_COMMON_PROPS} path="/app/:organization_id/templates/*"
                refreshCategories={refreshCategories}
                refreshDecks={refreshDecks} refreshCards={refreshCards}
                refreshQuizQuestions={refreshQuizQuestions}
              />
              <Templates
                {...ROUTES_COMMON_PROPS} path="/app/:organization_id/templates/deck/:deckId"
                tabStatus={"templates"}
                refreshCategories={refreshCategories}
                refreshDecks={refreshDecks} refreshCards={refreshCards}
                refreshQuizQuestions={refreshQuizQuestions}
                refreshAppContentScopes={refreshAppContentScopes}
              />
              <Templates
                {...ROUTES_COMMON_PROPS} path="/app/:organization_id/templates/cardStack/:cardStackId"
                tabStatus={"templates"}
                refreshCategories={refreshCategories}
                refreshDecks={refreshDecks} refreshCards={refreshCards}
                refreshQuizQuestions={refreshQuizQuestions}
              />
            </Router>
            {
              isTourPopEnabled && <TourCard />
            }

          </>
        )}
      </Layout>
      <div className="overlay" id="overlay"></div>
      <div className="app-dropdown-overlay" id="app-dropdown-overlay" style={{ right: '50px', transition: theme.animation.timing400 }}></div>
    </SnackbarProvider>
  );
};

const mapStateToProps = props => {
  const { channels, organization, isLoading, category, decks, cards, isTourPopEnabled, showAlert } = props.appBasicControls;
  return { channels, organizationData: organization, isApiInProgress: isLoading, category, decks, cards, isTourPopEnabled, showAlert };
};

const enhancer = compose(
  connect(mapStateToProps),
  React.memo
)
export default enhancer(App);
