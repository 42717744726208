import * as React from 'react';
import { connect } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client';
import { StatefulButtonGroup, MODE } from 'baseui/button-group';
import { Spinner } from 'baseui/spinner';
import StyledButton from '../styledButton';
import MasonryComponent from 'react-masonry-component';
import { useStyletron } from 'baseui';
import ReactPlayer from 'react-player';
import RightSidePageHeader from '../pageHeaders';
import InfiniteScroll from 'react-infinite-scroll-component';
import HelpQuestionMark from '../help/questionMarkHelp';
import Button from '../common/button';
import { Check, Plus } from 'baseui/icon';
import { client as filestackClient } from 'filestack-react';
import { libraries, filestackLibraries, getTags } from '../../quries';
import { deleteFilestackLibrary } from './mutations/deleteFilestackLibrary';
import AddLibrary from './addLibrary'
import AddVideo from './addVideo'
import AddJWPlayer from './addJWPlayer';
import LibraryModal from './libraryModal'
import { Skeleton } from 'baseui/skeleton'
import { client } from '../../apollo/client';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { Breadcrumbs } from "baseui/breadcrumbs";
import FileStackUpload from '../common/fileUpload/FileStackUpload';
import CloudinaryUploadWidget from './CloudinaryUploadWidget';
import { Accordion, Panel } from "baseui/accordion";
import { Input } from "baseui/input";
import { ArrowDownIcon, ArrowRightIcon } from './icons';
import { getExtension, isImage, signKey, getResourceType } from './utils'
import {
  useSnackbar,
  DURATION,
} from 'baseui/snackbar';
import { LabelMedium, ParagraphSmall } from 'baseui/typography';

import Axios from 'axios';
import './styles.css'
import DragAndDrop from './DragAndDrop';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import VideoPreviewModal from './videoPreviewModal';
import FileCard from './FileCard';
import FolderCard from './FolderCard';
import Styled from './styles';
import { getServerUrl } from '../../apollo/client';
import moment from 'moment';

const TAB_STATUS = {
  CLOUDINARY: 'cloudinary',
  FILESTACK: 'filestack',
  JWPLAYER: 'jwplayer',
  VIDEO: 'video',
  IMAGE: 'image',
  DOCUMENT: 'document'
};
const buttonOverrides = {
  BaseButton: {
    style: () => ({
      fontFamily: 'Roboto'
    })
  }
}
const timestamp = Math.round((new Date).getTime() / 1000);
const cardNumberPerPage = 12;

const Library = ({
  defaultTheme,
  dispatch,
  organization,
  jwPlayerData,
  ...props
}) => {
  const { cloudinarySettings, filestackSettings } = props;
  const [isAddOpen, setAddOpen] = React.useState(false);
  const [isAddVideoOpen, setAddVideoOpen] = React.useState(false);
  const [tab, setTab] = React.useState(TAB_STATUS.CLOUDINARY);
  const [selectedItem, setSelectedItem] = React.useState(null)
  const [signature, setSignature] = React.useState(null)
  const [isScriptLoaded, setScriptLoaded] = React.useState(false);
  const [cloudinaryData, setCloudinaryData] = React.useState(null);
  const [root_folder, setRootFolder] = React.useState('');
  const [expanded, setExpanded] = React.useState(['0']);
  const [isFetchingFiles, setIsLoading] = React.useState([]);
  const [isFolderDeleteOpen, setIsFolderDeleteOpen] = React.useState(false)
  const [isAssetDeleteOpen, setIsAssetDeleteOpen] = React.useState(false)
  const [isCreateFolderOpen, setIsFolderCreateOpen] = React.useState(false)
  const [selectedFolder, setSelectedFolder] = React.useState(null)
  const [selectedAsset, setSelectedAsset] = React.useState(null)
  const [isDeleteLoading, setDeleteLoading] = React.useState(false)
  const [newFolderName, setNewFolderName] = React.useState('')
  const [isAddJWVideoOpen, setAddJWVideoOpen] = React.useState(false);
  const [selectedVideo, setSelectedVideo] = React.useState(null)
  const [isJWLoading, setIsJWLoading] = React.useState(false);
  const [jwVideoData, setJWVideoData] = React.useState([]);
  const [site_id, setSiteId] = React.useState('');
  const [app_secret, setAppSecret] = React.useState('');
  const [hasMore, setHasMore] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(true);
  const [scrollData, setScrollData] = React.useState([]);
  const [filestackScrollData, setFileStackScrollData] = React.useState([]);

  const [css, theme] = useStyletron();
  const { enqueue, dequeue } = useSnackbar();
  const [tags, setTags] = React.useState([]);

  const [deleteFilestack] = useMutation(deleteFilestackLibrary);

  const { data: libraryData, loading: isLoading, refetch: refetchLibraries } = useQuery(
    libraries(organization.id),
    { fetchPolicy: 'network-only' }
  );
  const { data: filestackLibraryData, loading: isfileStackLoading, refetch: refetchFilestackLibraries } = useQuery(
    filestackLibraries(organization.id),
    { fetchPolicy: 'network-only' }
  );

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://widget.cloudinary.com/v2.0/global/all.js";
    script.async = true;
    script.onload = () => {
      setScriptLoaded(true)
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);


  const InfiniteScrollLoader = () => {
    if (!hasMore) return null;
    return (
      <div style={{ width: '90vw', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '2rem' }}>
        <Spinner />
      </div>
    )
  };

  const deleteJWMedia = async () => {
    const media_id = selectedVideo.id;
    const apiUrl = `https://api.jwplayer.com/v2/sites/${site_id}/media/${media_id}/`;
    const requestOptions = {
      method: 'DELETE',
      headers: { 'accept': 'application/json', 'Authorization': `Bearer ${app_secret}` },
    };
    try {
      const res = await fetch(apiUrl, requestOptions);
      onClose();
      onMessage('Video deleted successfully');
      getJWMediaList();
      return res;
    }
    catch {
      return false;
    }
  }

  const getJWMediaList = async () => {
    if (jwPlayerData && jwPlayerData.jwplayers_org && jwPlayerData.jwplayers_org.length > 0) {
      let { app_key, app_secret } = jwPlayerData.jwplayers_org[0];
      if (!app_key) {
        app_key = jwPlayerData.jwplayers_org[1]['app_key'];
        app_secret = jwPlayerData.jwplayers_org[1]['app_secret'];
      }
      const apiUrl = `https://api.jwplayer.com/v2/sites/${app_key}/media/?page_length=1000`;
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${app_secret}` },
      };
      setIsJWLoading(true)
      try {
        const apiResult = await fetch(apiUrl, requestOptions)
        const data = await apiResult.json()
        if (data && data.media) {
          let nextOptions = []
          for (let i = 0; i < data.media.length; i++) {
            const item = data.media[i]
            const apiUrl = "https://cdn.jwplayer.com/v2/media/" + item.id;
            const videoResult = await fetch(apiUrl)
            const videoData = await videoResult.json()
            try {
              const url = videoData?.playlist[0]?.sources[1]?.file
              const previewUrl = videoData?.playlist[0]?.image
              if (url) {
                nextOptions.push({
                  id: item.id,
                  size: videoData?.playlist[0]?.sources[1]?.filesize,
                  width: videoData?.playlist[0]?.sources[1]?.width,
                  height: videoData?.playlist[0]?.sources[1]?.height,
                  type: videoData?.playlist[0]?.sources[1]?.type,
                  title: item.metadata.title,
                  previewUrl: previewUrl,
                  description: item.metadata.description,
                  url: url,
                  duration: item.duration || null,
                  tags: item.metadata.tags
                })
              }
            } catch {

            }
          }
          setJWVideoData(nextOptions)
          setIsJWLoading(false);
        }
      } catch (e) {
        setJWVideoData([]);
        setIsJWLoading(false)
      };
    }
  }

  const loadTags = () => {
    client.query({
      query: getTags(organization.id),
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      const tags = [...data.tags_org].sort((a, b) => a.position - b.position)
      setTags(tags);
    })
  };

  React.useEffect(() => {
    loadTags()
  }, [])
  React.useEffect(() => {
    try {
      if (cloudinarySettings.cloudinaries_org[0]) {
        generateCloudinarySignature();
      }
    } catch {
    }
  }, [cloudinarySettings]);
  React.useEffect(() => {
    try {
      if (filestackSettings.filestacks_org[0]) {
      }
    } catch {
    }
  }, [filestackSettings]);
  React.useEffect(() => {
    try {
      if (cloudinarySettings.cloudinaries_org[0]) {
        listCloudinaryResources();
      }
    } catch {
    }
  }, [cloudinarySettings, root_folder]);

  React.useEffect(() => {
    setHasMore(true);
    setLoading(false);
    setPage(1);
  }, [tab])

  const onFolderDoubleClick = (path) => {
    setRootFolder(path)
  }

  const onCloseDeleteModal = () => {
    setIsAssetDeleteOpen(false);
    setIsFolderDeleteOpen(false);
    setIsFolderCreateOpen(false);
    setNewFolderName('')
  }

  const onDeleteAsset = async () => {
    try {
      const BASE_URI = getServerUrl();
      const { api_key, api_secret, cloud_name } = cloudinarySettings.cloudinaries_org[0];
      setDeleteLoading(true)
      if (selectedAsset.public_id) {
        const res = await Axios.post(`${BASE_URI}/cloudinary/delete_asset`, { timestamp, api_key, api_secret, cloud_name, public_id: selectedAsset.public_id });
        listCloudinaryResources();
      } else {
        await deleteFilestack({
          variables: {
            id: selectedAsset.id,
          }
        });
        await removeFileStackData(selectedAsset.handle);
        refetchFilestackLibraries();
      }
      setDeleteLoading(false)
      onCloseDeleteModal();
      enqueue(
        {
          startEnhancer: ({ size }) => <Check size={size} />,
          message: 'Asset deleted.',
          actionMessage: '',
          actionOnClick: () => {
          },
          focus: false
        },
        DURATION.medium,
      );
    } catch (error) {
      refetchFilestackLibraries();
      // enqueue(
      //     {
      //         startEnhancer: ({ size }) => <Delete size={size} />,
      //         message: 'Failed to delete file.',
      //         actionMessage: '',
      //         actionOnClick: () => {
      //         },
      //         focus: false
      //     },
      //     DURATION.medium,
      // );
      onCloseDeleteModal()
      setDeleteLoading(false)
    }
  }
  const onDeleteFolder = async () => {
    try {
      const BASE_URI = getServerUrl();
      setDeleteLoading(true)
      const { api_key, api_secret, cloud_name } = cloudinarySettings.cloudinaries_org[0];
      const res = await Axios.post(`${BASE_URI}/cloudinary/delete_folder`, { timestamp, api_key, api_secret, cloud_name, folder_path: selectedFolder.path, organization_id: organization.id });
      listCloudinaryResources();
      setDeleteLoading(false)
      onCloseDeleteModal()
      enqueue(
        {
          startEnhancer: ({ size }) => <Check size={size} />,
          message: 'Folder deleted.',
          actionMessage: '',
          actionOnClick: () => {
          },
          focus: false
        },
        DURATION.medium,
      );
    } catch (error) {
      alert(error)
      onCloseDeleteModal()
      setDeleteLoading(false)
    }
  }
  const onCreateFolder = async () => {
    try {
      const BASE_URI = getServerUrl();
      setDeleteLoading(true)
      const { api_key, api_secret, cloud_name } = cloudinarySettings.cloudinaries_org[0];
      const res = await Axios.post(`${BASE_URI}/cloudinary/folder`, { timestamp, api_key, api_secret, cloud_name, folder_path: root_folder + '/' + newFolderName, organization_id: organization.id });
      listCloudinaryResources();
      setDeleteLoading(false)
      onCloseDeleteModal()
      enqueue(
        {
          startEnhancer: ({ size }) => <Check size={size} />,
          message: 'Folder created.',
          actionMessage: '',
          actionOnClick: () => {
          },
          focus: false
        },
        DURATION.medium,
      );
    } catch (error) {
      alert(error)
      onCloseDeleteModal()
      setDeleteLoading(false)
    }
  }

  React.useEffect(() => {
    if (jwPlayerData && jwPlayerData.jwplayers_org && jwPlayerData.jwplayers_org.length > 0) {
      let { app_key, app_secret } = jwPlayerData.jwplayers_org[0];
      if (!app_key) {
        app_key = jwPlayerData.jwplayers_org[1]['app_key'];
        app_secret = jwPlayerData.jwplayers_org[1]['app_secret'];
      }
      if (app_key) setSiteId(app_key)
      if (app_secret) setAppSecret(app_secret)
    }
  }, [jwPlayerData])

  React.useEffect(() => {
    if (site_id && app_secret) {
      getJWMediaList();
    }
  }, [site_id, app_secret])

  React.useEffect(() => {
    setTimeout(() => {
      if (tab !== TAB_STATUS.JWPLAYER) return;
      const maxLimit = page * cardNumberPerPage;
      const filterOptions = jwVideoData.filter((i, index) => index < (maxLimit))
      if (jwVideoData.length > filterOptions.length) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
      setScrollData(filterOptions);
      if (loading) {
        setLoading(false);
      }
    }, 1000)
  }, [page, jwVideoData, tab]);

  React.useEffect(() => {
    setTimeout(() => {
      if (tab !== TAB_STATUS.FILESTACK) return;
      const maxLimit = page * cardNumberPerPage;
      if (filestackLibraryData) {
        const filterOptions = filestackLibraryData['filestack_libraries_org'].filter((i, index) => index < (maxLimit))
        if (filestackLibraryData['filestack_libraries_org'].length > filterOptions.length) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
        setFileStackScrollData(filterOptions);
        if (loading) {
          setLoading(false);
        }
      }
    }, 1000)
  }, [page, filestackLibraryData, tab]);

  const onAdd = () => {
    setAddOpen(true);
  }
  const onAddCloudinaryFile = () => {
    document.getElementById("upload_widget").click();
  }
  const onVideoAdd = () => {
    setAddVideoOpen(true);
  }
  const onJWVideoAdd = () => {
    setAddJWVideoOpen(true);
  }
  const onClose = () => {
    setAddOpen(false);
    setAddVideoOpen(false);
    setAddJWVideoOpen(false);
    setSelectedItem(null);
    setSelectedVideo(null);
  }

  const onDeleteFile = (fileItem) => {
    setSelectedAsset(fileItem);
    setIsAssetDeleteOpen(true);
  }
  const onDeleteFolderFiles = (folderItem) => {
    setSelectedFolder(folderItem);
    setIsFolderDeleteOpen(true);
  }
  const onDeleteFileStackItem = (fileItem) => {
    setSelectedAsset(fileItem);
    setIsAssetDeleteOpen(true);
  }

  const removeFileStackData = async (handle) => {
    if (!filestackSettings) return alert('Invalid Filestack account credential!')
    const { api_key, app_secret } = filestackSettings.filestacks_org[0];
    if (!api_key || !app_secret) return alert('Invalid Filestack account credential!')
    try {
      const timestamp = Math.round((new Date).getTime() / 1000);
      const policy = {
        "expiry": timestamp,
        handle
      }
      const base64Policy = Buffer.from(JSON.stringify(policy)).toString('base64');
      const signature = signKey(app_secret, base64Policy)
      const filestack = await filestackClient.init(api_key)
      const res = await filestack.remove(handle, { policy: base64Policy, signature });
      return res;
    } catch (error) {
      alert('Invalid filestack credential or something went wrong')
      throw new Error(error.toString())
    }
  }

  const onMessage = (msg) => {
    enqueue(
      {
        startEnhancer: ({ size }) => <Check size={size} />,
        message: msg,
        actionMessage: '',
        actionOnClick: () => {
        },
        focus: false
      },
      DURATION.medium,
    );
  }

  const generateCloudinarySignature = async () => {
    try {
      const BASE_URI = getServerUrl();

      const { api_key, api_secret, cloud_name } = cloudinarySettings.cloudinaries_org[0];
      const res = await Axios.post(`${BASE_URI}/cloudinary_signature`, { timestamp, api_key, api_secret, cloud_name, source: "uw", organization_id: organization.id });
      setSignature(res.data)
      return res.data
    } catch (error) {
      alert(error)
      return false;
    }
  }
  const listCloudinaryResources = async () => {
    try {
      setIsLoading(true)
      const BASE_URI = getServerUrl();

      const { api_key, api_secret, cloud_name } = cloudinarySettings.cloudinaries_org[0];
      const res = await Axios.post(`${BASE_URI}/cloudinary/resources`, { timestamp, api_key, api_secret, cloud_name, root_folder, organization_id: organization.id, source: 'admin' });
      setCloudinaryData(res.data);
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const onClickBreadcrumb = path => {
    if (path === 'Home') {
      return setRootFolder('');
    }
    const newPath = root_folder.indexOf(path);
    setRootFolder(root_folder.substring(0, newPath + path.length))
  }

  const childElements = libraryData && Array.isArray(libraryData.libraries_by_org) && libraryData.libraries_by_org.map(function (item) {
    const { type, library, title } = item;
    let url = null;
    try {
      url = library ? JSON.parse(library).metadata.full_url || JSON.parse(library).metadata.url : '';
    } catch (error) {
      // console.log(error)
    }
    console.log(url, type, tab);
    if (!url) return;
    if (type == 'image' && tab == 'image') {
      if (url.indexOf('jpg') > -1 || url.indexOf('png') > -1 || url.indexOf('jpeg') > -1)
        return (
          <img
            src={url}
            onClick={e => {
              setSelectedItem(item)
            }}
            alt='library'
            style={{
              objectFit: 'contain',
              marginLeft: '10px',
              padding: '5px',
              cursor: 'pointer',
              listStyleType: 'none',
              display: 'block'
            }}
          />
        )
    }
    if (type == 'video' && tab == 'video') {
      return (
        <ul style={{ marginLeft: '20px', padding: '5px', cursor: 'pointer', listStyleType: 'none' }}>
          <ReactPlayer controls={true} url={url} width="200px" height="260px" />
        </ul>
      )
    }
    if (type == 'document' && tab == 'document') {
      return (
        <div style={{ width: '120px', marginTop: '12px', marginRight: '12px', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <img
            src={'https://storage.googleapis.com/catalystxl-admin-api/graph/full/1BKhAkdSo-description_FILL0_wght400_GRAD0_opsz48.png'}
            onClick={e => {
              // window.open(url, '_blank');
              setSelectedItem(item)
            }}
            alt='library'
            style={{
              objectFit: 'contain',
              marginLeft: '10px',
              padding: '5px',
              cursor: 'pointer',
              listStyleType: 'none',
              display: 'block',
              height: '100px'
            }}
          />
          <LabelMedium>{title.substring(0, 20)}</LabelMedium>
        </div>
      )
    }
    return null;
  });

  const Loader = () => {
    return (
      <div style={{ width: '100%', marginLeft: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        {new Array(5).fill('').map(item => {
          return (
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '2rem' }}>
              {new Array(3).fill('').map(item => (
                <Skeleton
                  width="500px"
                  height="145px"
                  animation
                  overrides={{
                    Root: {
                      style: {
                        marginRight: '60px',
                        borderRadius: '20px'
                      },
                    },
                  }}
                />
              ))}
            </div>
          )
        })}
      </div>
    )
  }

  const CloudinaryLoader = () => {
    return (
      <div >
        <Skeleton
          width="100%%"
          height="70px"
          animation
          overrides={{
            Root: {
              style: {
                marginBottom: '1rem',
                borderRadius: '5px'
              },
            },
          }}
        />
        <div className='flex-container' style={{ marginLeft: '2rem' }}>
          {new Array(20).fill('').map(item => (
            <Skeleton
              width="320px"
              height="48px"
              animation
              overrides={{
                Root: {
                  style: {
                    margin: '1rem',
                    borderRadius: '5px'
                  },
                },
              }}
            />
          ))}
        </div>
        <Skeleton
          width="100%%"
          height="70px"
          animation
          overrides={{
            Root: {
              style: {
                marginTop: '1rem',
                borderRadius: '5px'
              },
            },
          }}
        />
      </div>
    )
  }

  const getLibrary = () => {
    if (selectedItem) {
      let url, uploaded_at, title, type;
      try {
        if (selectedItem?.library) {
          url = selectedItem.library ? JSON.parse(selectedItem.library).metadata.full_url : '';
          uploaded_at = selectedItem.library ? JSON.parse(selectedItem.library).uploaded_at : '';
          title = selectedItem.library ? JSON.parse(selectedItem.library).file_info.filename : '';
        } else if (selectedItem?.url) {
          url = selectedItem.url;
          title = JSON.parse(selectedItem.metadata)?.filename || ''
          uploaded_at = selectedItem?.created_at || ''
        }
      } catch (error) {
        console.log(error)
      }
      return { url, uploaded_at, title, type: selectedItem.type }
    }
    return null;
  }

  return (
    <div style={{ padding: '0.5rem' }}>
      <RightSidePageHeader
        icon={null}
        title={'Library'}
        rightContent={
          <StatefulButtonGroup
            mode={MODE.radio}
            initialState={{ selected: 0 }}

          >
            {Object.keys(TAB_STATUS).map(key => {
              return (
                <StyledButton
                  className={css({
                    fontSize: '14px !important', textTransform: 'uppercase'

                  })}
                  overrides={buttonOverrides}
                  onClick={() => setTab(TAB_STATUS[key])}>{TAB_STATUS[key]}</StyledButton>
              )
            })}
          </StatefulButtonGroup>
        }
        headerDescription={'Manage App Library Here'}
      />
      {isLoading || isJWLoading ? <Loader /> : (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', alignItems: 'center' }}>
            {tab === 'filestack' ? <FileStackUpload organization_id={organization.id} credential={filestackSettings ? filestackSettings.filestacks_org[0] : null} refetch={refetchFilestackLibraries} /> : null}
            {tab === 'cloudinary' ? (
              <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <Button
                  border_property={"true"}
                  onClick={onAddCloudinaryFile}
                  text='Upload files'
                  style={{ textTransform: 'capitalize' }}
                  startEnhancer={() => (
                    <Plus
                      size={16}
                      overrides={{
                        Svg: {
                          style: {
                            backgroundColor: 'white',
                            color: '#5D64AB',
                            borderRadius: '2px',
                          },
                        },
                      }}
                    />
                  )}
                  endEnhancer={() => (
                    <HelpQuestionMark text={`Upload files to the cloudinary`} button />
                  )}
                />
              </div>
            ) : null}
            {tab == 'image' && (
              <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <Button
                  border_property={"true"}
                  onClick={onAdd}
                  text='Add Image'
                  style={{ textTransform: 'capitalize' }}
                  startEnhancer={() => (
                    <Plus
                      size={16}
                      overrides={{
                        Svg: {
                          style: {
                            backgroundColor: 'white',
                            color: '#5D64AB',
                            borderRadius: '2px',
                          },
                        },
                      }}
                    />
                  )}
                  endEnhancer={() => (
                    <HelpQuestionMark text={`add new image to the system`} button />
                  )}
                />
              </div>
            )}

            {tab == 'video' && (
              <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <Button
                  border_property={"true"}
                  onClick={onVideoAdd}
                  text='Add Video'
                  style={{ textTransform: 'capitalize' }}
                  startEnhancer={() => (
                    <Plus
                      size={16}
                      overrides={{
                        Svg: {
                          style: {
                            backgroundColor: 'white',
                            color: '#5D64AB',
                            borderRadius: '2px',
                          },
                        },
                      }}
                    />
                  )}
                  endEnhancer={() => (
                    <HelpQuestionMark text={`add new video to the system`} button />
                  )}
                />
              </div>
            )}
            {tab == 'jwplayer' && (
              <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <Button
                  border_property={"true"}
                  onClick={onJWVideoAdd}
                  text='Add JWVideo'
                  style={{ textTransform: 'capitalize' }}
                  startEnhancer={() => (
                    <Plus
                      size={16}
                      overrides={{
                        Svg: {
                          style: {
                            backgroundColor: 'white',
                            color: '#5D64AB',
                            borderRadius: '2px',
                          },
                        },
                      }}
                    />
                  )}
                  endEnhancer={() => (
                    <HelpQuestionMark text={`add new video to the system`} button />
                  )}
                />
              </div>
            )}

          </div>
          <div>
            <div>
              {tab === 'cloudinary' ? (
                <div className="App" style={{ padding: '1rem' }}>
                  <h3 style={{ color: '#333b4c' }}>Cloudinary Upload Widget</h3>
                  {isScriptLoaded && cloudinarySettings && signature ? <CloudinaryUploadWidget signature={signature} timestamp={timestamp} cloudinarySettings={cloudinarySettings} /> : null}
                  {cloudinarySettings ? <DragAndDrop cloudinarySettings={cloudinarySettings} onMessage={onMessage} refetch={listCloudinaryResources} organization_id={organization.id} refetchLibraries={refetchLibraries}/> : null}
                  <div style={{ margin: '1rem' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <span className='cloudIcon' onClick={() => setRootFolder('')}>
                        <svg class="svgIcon" preserveAspectRatio="xMidYMid" fill='#333b4c' viewBox="0 0 24 24"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96z"></path></svg>
                      </span>
                      <Breadcrumbs>
                        {(['Home', ...(root_folder.split('/'))]).map(i => <span style={{ cursor: 'pointer' }} onClick={() => onClickBreadcrumb(i)}>{i}</span>)}
                      </Breadcrumbs>
                      <svg className="folder_addIcon" onClick={() => setIsFolderCreateOpen(true)} preserveAspectRatio="xMidYMid" fill='#333b4c' viewBox="0 0 24 24"><path d="M21.6 4.8H12L9.6 2.4H2.4A2.38 2.38 0 0 0 .01 4.8L0 19.2a2.4 2.4 0 0 0 2.4 2.4h19.2a2.4 2.4 0 0 0 2.4-2.4v-12a2.4 2.4 0 0 0-2.4-2.4zm-1.2 9.6h-3.6V18h-2.4v-3.6h-3.6V12h3.6V8.4h2.4V12h3.6v2.4z"></path></svg>
                    </div>
                    {isFetchingFiles ? <CloudinaryLoader /> : (
                      <>
                      <ParagraphSmall>
                    Limit: {cloudinaryData.rate_limit_remaining}/{cloudinaryData.rate_limit_allowed} {' '}Reset At: {moment(cloudinaryData.rate_limit_reset_at).format('lll')}
                      </ParagraphSmall>
                      <Accordion
                        onChange={({ expanded }) => setExpanded(expanded)}
                        accordion
                        renderAll
                        initialState={{ expanded: expanded }}
                        overrides={{ Root: { style: { margin: 0 } } }}
                      >
                        <Panel title={
                          <div className={css({ display: 'flex', alignItems: 'center', color: '#333b4c' })}>
                            {expanded[0] === '0' ? <ArrowDownIcon /> : <ArrowRightIcon />}
                            {"Folders " + `(${cloudinaryData?.root_folders?.length || '0'})`}
                          </div>}>
                          <div className='flex-container'>
                            {cloudinaryData && cloudinaryData.root_folders && cloudinaryData.root_folders.map((folderItem, index) => (
                              <FolderCard data={folderItem} onDelete={onDeleteFolderFiles} onDoubleClick={onFolderDoubleClick} index={index} key={index} />
                            ))}
                          </div>
                        </Panel>
                        <Panel title={
                          <div className={css({ display: 'flex', alignItems: 'center', color: '#333b4c' })}>
                            {expanded[0] === '1' ? <ArrowDownIcon /> : <ArrowRightIcon />}
                            {"Assets " + `(${cloudinaryData?.root_files?.length || '0'})`}
                          </div>}>
                          <div className='flex-container '>
                            {cloudinaryData && cloudinaryData.root_files && cloudinaryData.root_files.map((fileItem, index) =>
                              (<FileCard data={fileItem} onDelete={onDeleteFile} onMessage={onMessage} index={index} key={index} />)
                            )}
                          </div>
                        </Panel>
                      </Accordion>
                      </>
                    )}
                  </div>
                </div>
              ) : null}
              {tab === 'jwplayer' ? (
                <InfiniteScroll
                  dataLength={scrollData.length}
                  next={() => {
                    if (loading || !hasMore) { return; }
                    setLoading(true);
                    let p = page;
                    setPage(++p);
                  }}
                  style={{
                    display: 'flex', flexWrap: 'wrap',
                    height: '100%',
                    minWidth: '500px',
                  }}
                  hasMore={hasMore}
                >
                  {scrollData.map((item, index) => {
                    const { url, title, previewUrl, size, duration, width, height, type } = item;
                    const cardData = {
                      filename: title,
                      secure_url: url,
                      url,
                      format: getExtension(url),
                      duration,
                      bytes: size,
                      resource_type: getResourceType(url),
                      ...item
                    }
                    return (
                      <FileCard data={cardData} onDelete={deleteJWMedia} key={index} index={index} />
                    )
                    return (
                      <Styled.VideoContainer onClick={() => setSelectedVideo(item)}>
                        <Styled.VideoPreview src={previewUrl} />
                        <Styled.VideoTitleContainer>
                          {title}
                        </Styled.VideoTitleContainer>
                      </Styled.VideoContainer>
                    )
                  })}
                  {loading && <InfiniteScrollLoader />}
                </InfiniteScroll>
              ) : null}
              {(tab !== 'filestack' && tab !== 'cloudinary' && tab !== 'jwplayer') ? (
                <div>
                  {libraryData && Array.isArray(libraryData.libraries_by_org) && tab == 'image' ? (
                    <ResponsiveMasonry
                      columnsCountBreakPoints={{ 350: 2, 750: 4, 900: 6 }}
                    >
                      <Masonry>{childElements}</Masonry>
                    </ResponsiveMasonry>
                  ) : (
                      <MasonryComponent>{childElements}</MasonryComponent>
                    )}
                  {(!isLoading && !libraryData || libraryData.libraries_by_org.length == 0) && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '60vh' }}>
                      <p style={{ textAlign: 'center' }}>No library data found</p>
                    </div>
                  )}
                </div>
              ) : null}
              {tab === 'filestack' ? (
                <div style={{ margin: '1rem' }}>
                  <h3 style={{ color: '#333b4c' }}>FileStack Upload</h3>
                  <InfiniteScroll
                    dataLength={filestackScrollData.length}
                    next={() => {
                      if (loading || !hasMore) { return; }
                      setLoading(true);
                      let p = page;
                      setPage(++p);
                    }}
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      height: '100%',
                      // height: '670px',
                      minWidth: '500px',
                    }}
                    hasMore={hasMore}
                  >
                    {filestackScrollData.map((item, index) => {
                      const { url, type, metadata, id } = item;
                      try {
                        let fileStackData = null
                        const { filename, size, duration, handle } = JSON.parse(metadata);
                        const resizeUrl = isImage(filename) ?
                          url
                          : url;
                        fileStackData = {
                          filename, secure_url: resizeUrl, bytes: size, handle, format: getExtension(filename), duration, width: 300, height: 300, resource_type: getResourceType(filename), id
                        }
                        return (
                          <FileCard data={fileStackData} onDelete={onDeleteFileStackItem} key={index} index={index} />
                        )
                      } catch {
                        return null;
                      }
                    })}
                    {loading && <InfiniteScrollLoader />}
                  </InfiniteScroll>
                  {(!isfileStackLoading && !filestackLibraryData || filestackLibraryData?.filestack_libraries_org.length == 0) && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '60vh' }}>
                      <p style={{ textAlign: 'center' }}>No library data found</p>
                    </div>
                  )}
                </div>
              ) : null}
            </div>

            {
              isAddOpen && (
                <AddLibrary onClose={onClose} tags={tags} refetchLibraries={refetchLibraries} organization_id={organization.id} />
              )
            }
            {
              isAddVideoOpen && (
                <AddVideo onClose={onClose} tags={tags} refetchLibraries={refetchLibraries} organization_id={organization.id} />
              )
            }

            {selectedItem && <LibraryModal onClose={onClose} library={getLibrary()} onMessage={onMessage} />}
          </div>
        </div>
      )}
      <Modal onClose={onCloseDeleteModal} isOpen={isFolderDeleteOpen}>
        <ModalHeader>Delete Folder and Assets?</ModalHeader>
        <ModalBody>
          Are you sure you want to delete the '{selectedFolder ? selectedFolder.name : 'this'}' folder?
                    All assets inside this folder will be deleted.
                </ModalBody>
        <ModalFooter>
          <ModalButton kind="tertiary" onClick={onCloseDeleteModal}>
            Cancel
                    </ModalButton>
          <ModalButton onClick={onDeleteFolder} isLoading={isDeleteLoading}>Delete</ModalButton>
        </ModalFooter>
      </Modal>
      <Modal onClose={onCloseDeleteModal} isOpen={isAssetDeleteOpen}>
        <ModalHeader>Delete Asset?</ModalHeader>
        <ModalBody>
          Are you sure you want to delete the '{selectedAsset ? selectedAsset.filename : 'this'}' asset?
                </ModalBody>
        <ModalFooter>
          <ModalButton kind="tertiary" onClick={onCloseDeleteModal}>
            Cancel
                    </ModalButton>
          <ModalButton onClick={onDeleteAsset} isLoading={isDeleteLoading}>Delete</ModalButton>
        </ModalFooter>
      </Modal>
      <Modal onClose={onCloseDeleteModal} isOpen={isCreateFolderOpen}>
        <ModalHeader>Add Folder</ModalHeader>
        <ModalBody>
          <Input
            value={newFolderName}
            onChange={e => setNewFolderName(e.target.value)}
            placeholder="Enter the folder name"
            clearOnEscape
          />
        </ModalBody>
        <ModalFooter>
          <ModalButton kind="tertiary" onClick={onCloseDeleteModal}>
            Cancel
                    </ModalButton>
          <ModalButton onClick={onCreateFolder} disabled={!newFolderName} isLoading={isDeleteLoading}>Save</ModalButton>
        </ModalFooter>
      </Modal>
      {
        isAddJWVideoOpen && (
          <AddJWPlayer
            onClose={onClose}
            tags={tags}
            refetchLibraries={getJWMediaList}
            organization_id={organization.id}
            site_id={site_id}
            app_secret={app_secret}
          />
        )
      }

      {selectedItem && <LibraryModal onClose={onClose} library={getLibrary()} onMessage={onMessage} />}
      {selectedVideo && <VideoPreviewModal onClose={onClose} library={selectedVideo} onDelete={deleteJWMedia} />}
    </div>
  );
};

const mapStateToProps = props => {
  let {
    theme,
    organization,
    cloudinarySettings,
    filestackSettings,
    jwPlayerData,
  } = props.appBasicControls;
  return {
    defaultTheme: theme,
    organization,
    cloudinarySettings,
    filestackSettings,
    jwPlayerData,
  };
};
export default connect(mapStateToProps)(Library);
