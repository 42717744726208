import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { H6 } from 'baseui/typography';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { ParagraphSmall, LabelMedium, ParagraphLarge } from 'baseui/typography';
import { Input } from 'baseui/input';
import gql from 'graphql-tag';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { Select, TYPE, StatefulSelect, SingleSelect } from 'baseui/select';
import ModalStateContainer from '../modalStateContainer';
import { apiQuery } from '../apiMappings/query';

import styled from '@emotion/styled';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { client } from '../../apollo/client';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import titlesLength from '../../../data/titlesLength.json';
import { editorBtnOptions as CardBuilderOptions } from '../../../data/cardBuilderOptions';
import * as FontAwesome from 'react-icons/fa';
import { trim, startCase, replace } from 'lodash';
import svgIcons from '../../svg';
import { useStyletron } from 'baseui';
import { Button, SHAPE, SIZE } from 'baseui/button';
import store from '../../redux/store';
import { setCreatedComponent, setOrg } from '../../redux/actions/appBasicControls';
import { scrollToBottom, scrollToElement } from '../builder/builderUtils/helpers';
import { FileUploader } from "baseui/file-uploader";
import { getServerUrl } from '../../apollo/client';
import axios from 'axios';
import { sourceByType } from '../../util';
import { slideImageGuideline } from '../help/imageSizeGuidelines.json';
import ImageUploadCXL from '../imageUploaderCXL';
import { useDispatch } from 'react-redux';
import { organization } from '../../quries';
import { KIND as ButtonKind } from "baseui/button";
import BaseUiTextArea from '../modules/generic_modules/baseuiTextArea';
// test
const dispatch = store.dispatch;

const COMPONENTS_TYPE = [
  {
    id: 'Slide',
    label: 'Slider',
    // bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    bgImg: require("../../images/channel_type_img.png"),
    // iconName: 'sliders-h',
    customIcon: svgIcons.SliderDeckIcon,
    description: `You might think of a content slider module as a slideshow of rows, in which each slide has any or all of the following components::

    A background (color, photo, video, or none)
    A headline and subheadline of text
    A link that performs some type of action`
  },
  {
    id: 'CardStackModule',
    label: 'Card Stack',
    // bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    bgImg: require("../../images/channel_type_img.png"),
    // iconName: 'sliders-h',
    customIcon: svgIcons.BlackCardIcon,
    description: 'An array of cards that when flipped over reveal content information.'
  },
  {
    id: 'DeckWithCardModule',
    label: 'Deck With Cards',
    // bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
    bgImg: require("../../images/channel_type_img.png"),
    // iconName: 'sliders-h',
    customIcon: svgIcons.BlackCardIcon,
    description: 'An array of cards that when flipped over reveal content information.'
  },
  // {
  //   id: 'CopyCardModule',
  //   label: 'Copy Card',
  //   // bgImg: 'https://storage.googleapis.com/catalystxl-admin-api/graph/full/8EORdu4Ju-nature1.jpg',
  //   bgImg: require("../../svg/copy_card_icon.svg"),
  //   // iconName: 'sliders-h',
  //   customIcon: svgIcons.CopyCardIcon
  // },
  ...CardBuilderOptions.filter((el) => el.addInHomeComponent).map((el) => {
    return {
      id: el.__typename,
      label: el.display,
      iconName: el.iconName,
      customIcon: el.customIcon,
      bgImg: el.bgImg,
      description: el.description
    }
  })
];

const itemProps = {
  // backgroundColor: 'mono300',
  minHeight: '25vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover !important',
  flexDirection: 'column',
  boxShadow: '5px 10px 18px #888888',
  border: '1px solid',
  borderRadius: '5px'
};


const addComponentMutation = gql`
mutation createHomeComponent(
    $title: String!,
    $organization_id: ID!,
    $position: Int!,
    $app_content_scope_id: ID!
    $component_type: String
    $is_single_form: Boolean
    $is_stepped_form: Boolean
    $form_report_type: String
    $style_override: String
) {
    createHomeComponent(
        title: $title,
        organization_id: $organization_id,
        position: $position,
        app_content_scope_id: $app_content_scope_id
        component_type: $component_type
        is_single_form: $is_single_form
        is_stepped_form: $is_stepped_form
        form_report_type: $form_report_type
        style_override: $style_override
    ) {
        id
        title
    }
}
`;

const updateComponentMutation = gql`
mutation updateHomeComponent(
    $id: ID!,
    $title: String,
    $position: Int,
    $public: Boolean,
    $archived: Boolean,
    $published: Boolean
    $component_type: String
    $has_data_source_connected: Boolean
    $api_path_id: ID
    $is_single_form: Boolean
    $is_stepped_form: Boolean
    $form_module_intro_heading: String
    $disable_filter_button: Boolean
    $form_module_intro_description: String
    $form_module_intro_cover_image: String
    $form_module_intro_button_text: String
    $form_module_step_button_text: String
    $form_module_last_step_button_text: String
    $form_module_scoring_script: String
    $form_module_is_first_form_meta: Boolean
    $form_report_type: String
    $form_module_intro_cover_image_upload: Upload
    $style_override: String
) {
    updateHomeComponent(
        id: $id,
        title: $title,
        position: $position,
        public: $public,
        archived: $archived,
        published: $published
        component_type: $component_type,
        has_data_source_connected: $has_data_source_connected
        api_path_id: $api_path_id
        is_single_form: $is_single_form
        is_stepped_form: $is_stepped_form
        form_module_intro_heading: $form_module_intro_heading
        disable_filter_button: $disable_filter_button
        form_module_intro_description: $form_module_intro_description
        form_module_intro_cover_image: $form_module_intro_cover_image
        form_module_intro_button_text: $form_module_intro_button_text
        form_module_step_button_text: $form_module_step_button_text
        form_module_last_step_button_text: $form_module_last_step_button_text
        form_module_scoring_script: $form_module_scoring_script
        form_module_is_first_form_meta: $form_module_is_first_form_meta
        form_module_intro_cover_image_upload: $form_module_intro_cover_image_upload
        form_report_type: $form_report_type
        style_override: $style_override
    ) {
        title
    }
}
`;

const LeftPanel = styled.div`
  width: 30%; /* Adjust the width as needed */
  background-color: #f5f5f5; /* Adjust the background color as needed */
  padding: 20px;
`;


const CardContainer = styled.div`
  border-color: ${props => (props.selected ? 'blue' : props.backgroundColor || 'mono300')} !important;
  height: 224px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
`;

const ComponentIcon = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
`;

const componentsProps = {
  height: '200px', // Adjust the card height as needed
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
};


const CustomIcon = styled.img`
  width: 14px;
  height: 14px;
  font-weight: lighter;
`

const FlexItemContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const CardInfoContainer = styled.div`
  position: absolute;
  opacity: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;        
  transition: opacity 0.1s 0.3s, -webkit-transform 0.4s;
  transition: transform 0.4s, opacity 0.1s 0.3s;
  transition: transform 0.4s, opacity 0.1s 0.3s, -webkit-transform 0.4s;  
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`

const addComponent = (data) => {
  return client.mutate({
    mutation: addComponentMutation,
    variables: data
  })
};

const updateComponent = (data) => {
  return client.mutate({
    mutation: updateComponentMutation,
    variables: data
  })
}

export default ({
  component,
  position,
  app_content_scope_id,
  organization_id,
  onClose,
  refetchHomeComponents,
  ...rest
}) => {
  console.log(component, rest);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenComponentTypeModal, setIsOpenComponentTypeModal] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false)
  const [title, setTitle] = React.useState(component.title || '');
  const [publicS, setPublic] = React.useState(component.public || false);
  const [published, setPublished] = React.useState(component.published || false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [componentType, setComponentType] = React.useState(component.component_type || (component.id ? 'Slide' : null));
  const [isFormSingle, setIsFormSingle] = React.useState(true);
  const [isSteppedForm, setIsSteppedForm] = React.useState(false);
  const id = component.id;
  const archived = component.archived;
  const [paths, setPaths] = React.useState([]);

  const [isImportModalOpen, setIsImportModalOpen] = React.useState(false);
  const [importFile, setImportFile] = React.useState(null);
  const [importLoading, setImportLoading] = React.useState(false);
  const [documentId, setDocumentId] = React.useState('');
  const [separatorRegex, setSeparatorRegex] = React.useState('');

  const [has_data_source_connected, set_has_data_source_connected] = React.useState(component.has_data_source_connected || false);
  const [api_path_id, set_api_path_id] = React.useState(component.api_path_id || null);
  const { is_api_settings_on, hidden_home_components } = store.getState().appBasicControls.organization_settings;
  const FILTERED_COMPONENT_TYPES = React.useMemo(() => {
    return COMPONENTS_TYPE.filter(el => {
      const arr = hidden_home_components && hidden_home_components.split(',') || [];
      const isExist = arr.find(el1 => el1 === el.id);
      if (!isExist) {
        return el;
      }
    }).sort((a, b) => {
      let x1 = a.label.includes('(Advanced)') ? 1 : 0;
      let x2 = b.label.includes('(Advanced)') ? 1 : 0;
      return x1 - x2;
    })
  }, [hidden_home_components])

  console.log(11122222, FILTERED_COMPONENT_TYPES)
  console.log(23333333, COMPONENTS_TYPE)
  const [css, theme] = useStyletron();

  const dispatch = useDispatch();

  // Cardware Form Module Keys
  const [uploadedFile, setUploadedFile] = React.useState(null);
  const [preview_image, setPreview] = React.useState(null);
  const getSrc = data => {
    if (!data) return data;
    return sourceByType(data, 'full_url');
  };
  const [image, setImage] = React.useState(getSrc(component.form_module_intro_cover_image));
  const [form_module_intro_heading, set_form_module_intro_heading] = React.useState(component.form_module_intro_heading || '');
  const [form_module_intro_description, set_form_module_intro_description] = React.useState(component.form_module_intro_description || '');
  const [form_module_intro_button_text, set_form_module_intro_button_text] = React.useState(component.form_module_intro_button_text || '');
  const [form_module_step_button_text, set_form_module_step_button_text] = React.useState(component.form_module_step_button_text || '');
  const [form_module_last_step_button_text, set_form_module_last_step_button_text] = React.useState(component.form_module_last_step_button_text || '')
  const [form_module_scoring_script, set_form_module_scoring_script] = React.useState(component.form_module_scoring_script || '');
  const [form_module_is_first_form_meta, set_form_module_is_first_form_meta] = React.useState(component.form_module_is_first_form_meta || false);

  const [form_report_type, set_form_report_type] = React.useState(component.form_report_type || 'list');

  const [disable_filter_button, set_disable_filter_button] = React.useState(component.disable_filter_button || false);
  const [style_override, set_style_override] = React.useState(component.style_override || '');

  React.useEffect(() => {
    client.query({
      query: apiQuery(),
      fetchPolicy: 'network-only',
      variables: {
        data: {
          model: "api_paths",
          query: `{\"where\": {\"organization_id\": \"${organization_id}\"}}`
        }
      }
    }).then((result) => {
      let response = result.data.cxl_internal_common_query;
      if (response.status) {
        setPaths(JSON.parse(response.data));
      }
    });
  }, []);

  React.useEffect(() => {
    if (component && Object.keys(component).length > 0) {
      setIsFormSingle(component.is_single_form == undefined ? true : component.is_single_form)
      setIsSteppedForm(component.is_stepped_form == undefined ? false : component.is_stepped_form)
    }
  }, [component])

  React.useEffect(() => {
    if (id) setIsOpen(true);
    else setIsOpenComponentTypeModal(true);
  }, [id]);

  const onSave = (req = {}) => {
    const { archived = false } = req;
    setIsLoading(true);
    let dataToSave = {
      id, title, public: publicS, published, archived,
      form_report_type,
      component_type: componentType,
      ...id ? {
        has_data_source_connected,
        api_path_id,
        form_module_intro_heading,
        form_module_intro_description,
        form_module_intro_button_text,
        form_module_step_button_text,
        form_module_last_step_button_text,
        form_module_scoring_script,
        form_module_is_first_form_meta,
        form_report_type,
        disable_filter_button,
        form_module_intro_cover_image_upload: uploadedFile,
        style_override
      } : { organization_id, app_content_scope_id, position }
    };
    if (componentType === 'SharePointFormModule') {
      dataToSave['is_single_form'] = isFormSingle;
      dataToSave['is_stepped_form'] = isSteppedForm;
    }
    let method = id ? updateComponent : addComponent;
    method(dataToSave)
      .then(async ({ data }) => {
        await refetchHomeComponents();
        setIsOpen(false);
        setIsDelete(false);
        onClose();
        if (data) {
          dispatch(setCreatedComponent(data.createHomeComponent));
          setTimeout(() => {
            scrollToBottom('app-container', 0);
            dispatch(setCreatedComponent(undefined));
          }, 300);

        }
      });
  };


  const onImportComponentClick = () => {
    setIsImportModalOpen(true);
  }

  const onClickImport = () => {
    setImportLoading(true);

    const formData = new FormData();
    formData.append('file', importFile);
    formData.append('organization_id', organization_id);
    let url = ''
    if (componentType == 'CardStackModule') {
      url = `${getServerUrl()}/card_stack_import`;
      formData.append('home_component_id', id);
    } else if (componentType == 'SharePointFormModule') {
      url = `${getServerUrl()}/sharepoint_forms_module_import`;
      formData.append('channel_id', app_content_scope_id);
    } else if (componentType == 'LocationModule') {
      url = `${getServerUrl()}/import_locations_module_from_csv`;
      formData.append('home_component_id', id);
      formData.append('app_content_scope_id', app_content_scope_id);
      console.log(app_content_scope_id, rest);
    }

    if (componentType == 'CardStackModule' && documentId) {
      return axios.post(`${getServerUrl()}/import_card_stack_from_google_doc`, {
        organization_id,
        home_component_id: id,
        document_id: documentId,
        separatorRegex
      })
        .then(async (result) => {
          console.log(result);
          await refetchHomeComponents();
          setIsImportModalOpen(false);
          setIsOpen(false);
          setIsDelete(false);
          setImportLoading(false);
          onClose();
        })
    }

    if (!url) return;

    axios.post(url, formData)
      .then(async (result) => {
        console.log(result);
        fetchIntegrations();
        await refetchHomeComponents();
        setIsImportModalOpen(false);
        setIsOpen(false);
        setIsDelete(false);
        onClose();
      }).catch((err) => {
        console.log('error import', err)
      }).finally(() => {
        setImportLoading(false);
      })

  }

  const onChangeFormReportType = (type = 'list') => {
    set_form_report_type(type);
  }

  function fetchIntegrations() {
    console.log('fetchIntegrations')
    client.query({
      query: organization(organization_id),
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      console.log('organization', data.organization)
      dispatch(setOrg(data.organization))
    }).catch(err => {
      console.log('fetchIntegrations error', err)
    })
  }



  const getModalTitle = () => {
    let type1 = id ? 'Update' : 'Add';
    let component = COMPONENTS_TYPE.find((el) => el.id == componentType);
    let type2 = component.label;
    return `${type1} ${type2} Component`;
  };

  const getSelectComponentOptionLabel = ({ option }) => {
    const trimmedName = `Fa${trim(startCase(option.iconName))}`;
    const convertedName = replace(trimmedName, /\s/g, '');
    const Icon = FontAwesome[convertedName];
    return (
      <div style={{ display: 'flex' }}>

        {/* {option.iconName && (<Icon style={{ marginRight: '8px', marginTop: '8px' }} />)}
        {option.customIcon && (<CustomIcon src={option.customIcon} style={{ marginRight: '8px', marginTop: '8px' }} />)} */}
        <ParagraphLarge >
          {option.label}
        </ParagraphLarge>
      </div>
    );
  }

  const getComponentTypes = () => {
    return COMPONENTS_TYPE.map((ele, idx) => {
      const onClickComponent = () => {
        if (componentType !== ele.id) {
          ele ? setComponentType(ele.id) : setComponentType(null)
          ele ? setIsFormSingle(ele.is_single_form) : setIsFormSingle(true)
          ele ? setIsSteppedForm(ele.is_stepped_form) : setIsSteppedForm(false)
        } else {
          setComponentType(null)
          setIsFormSingle(true)
          setIsSteppedForm(false)
        }
      }

      const selectComponentText = componentType == ele.id ? 'Selected' : 'Select Component';
      return (
        <FlexGridItem
          {...itemProps}
          className={css({
            boxShadow: '5px 10px 18px #888888',
            borderRadius: '5px',
            backgroundImage: `url(${require("../../images/channel_type_img.png")})`,
            ':hover .component_button_container': {
              visibility: 'visible',
              opacity: 1,
              '-webkit-transform': 'translateY(0px)',
              transform: 'translateY(0px)',
              '-webkit-transition': 'opacity 0.1s, -webkit-transform 0.4s',
              transition: 'visibility opacity 0.1s, -webkit-transform 0.4s',
              transition: 'transform 0.4s, opacity 0.1s',
              transition: 'transform 0.4s, opacity 0.1s, -webkit-transform 0.4s'

            }
          })}
        >
          <FlexItemContainer>
            <CardInfoContainer className='component_button_container'>
              <Button
                shape={SHAPE.pill}
                size={SIZE.compact}
                onClick={onClickComponent}
              >
                {selectComponentText}
              </Button>
              <div style={{ height: '10px' }} />
              {/* <Button
                shape={SHAPE.pill}
                size={SIZE.compact}
              >
                Text Button
              </Button> */}
            </CardInfoContainer>

          </FlexItemContainer>
          <div
            className={css({
              width: '100%',
              backgroundColor: 'white',
              padding: '10px',
              paddingTop: '15px',
              paddingBottom: '15px',
              borderBottomLeftRadius: '5px',
              borderBottomRightRadius: '5px',
            })}
          >
            <LabelMedium>{ele.label}</LabelMedium>

          </div>
        </FlexGridItem>
      )
    })
  }

  const renderComponentTypes = () => {
    return (
      <FlexGrid
        flexGridColumnCount={[1, 1, 2, 4]}
        flexGridColumnGap="scale800"
        flexGridRowGap="scale800"
        marginBottom="scale800"
      >
        {getComponentTypes()}
      </FlexGrid>
    )
  }

  const outerGridOverride = {
    marginTop: '30px',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  };
  console.log('add componentType', componentType);
  const canShowImport = (id && (componentType == 'CardStackModule' || componentType == 'LocationModule')) || componentType == 'SharePointFormModule'
  const importModalTitle = `Import from ${id && (componentType == 'CardStackModule' || componentType == 'LocationModule') ? 'CSV or Doc' : 'JSON'}`;

  const SharepointFormModuleFields = React.useMemo(() => {
    if (componentType !== 'SharePointFormModule') return null;
    return (
      <>
        <Cell
          span={[1, 12]}
          overrides={{
            Cell: {
              style: () => ({
                paddingLeft: '0px !important',
              }),
            },
          }}
        >
          <LabelMedium style={{ marginBottom: '16px' }}>Form Type</LabelMedium>
          <div style={{ marginLeft: '16px' }}>
            <Checkbox
              checked={isFormSingle}
              onChange={e => setIsFormSingle(!isFormSingle)}
              labelPlacement={LABEL_PLACEMENT.right}
            >
              <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Is Single Form?</ParagraphSmall>
            </Checkbox>
          </div>
          <div style={{ marginLeft: '16px' }}>
            <Checkbox
              checked={isSteppedForm}
              onChange={e => setIsSteppedForm(!isSteppedForm)}
              labelPlacement={LABEL_PLACEMENT.right}
            >
              <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Is Stepped Form?</ParagraphSmall>
            </Checkbox>
          </div>
        </Cell>
        <Cell
          span={[1, 12]}
          overrides={{
            Cell: {
              style: () => ({
                paddingLeft: '0px !important',
              }),
            },
          }}
        >
          <LabelMedium style={{ marginBottom: '16px' }}>Form Report Type</LabelMedium>
          <div style={{ marginLeft: '16px' }}>
            <Checkbox
              checked={form_report_type === 'list'}
              onChange={e => onChangeFormReportType('list')}
              labelPlacement={LABEL_PLACEMENT.right}
            >
              <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>List</ParagraphSmall>
            </Checkbox>
          </div>
          <div style={{ marginLeft: '16px' }}>
            <Checkbox
              checked={form_report_type === 'table'}
              onChange={e => onChangeFormReportType('table')}
              labelPlacement={LABEL_PLACEMENT.right}
            >
              <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Table</ParagraphSmall>
            </Checkbox>
          </div>
        </Cell>
      </>
    )
  }, [componentType, isFormSingle, isSteppedForm]);

  const CardwareFormModuleFields = React.useMemo(() => {
    const canShow = componentType === 'CardwareFormModule' && id
    if (!canShow) return null;

    return (
      <Cell
        span={[1, 12]}
        overrides={{
          Cell: {
            style: () => ({
              paddingLeft: '0px !important',
            }),
          },
        }}
      >
        <hr style={{ height: '2px', margin: 0, marginTop: '16px', marginBottom: '16px', background: '#E2E2E2' }} />
        <LabelMedium style={{ marginBottom: '16px' }}>Advance Cardware Form Module Setup</LabelMedium>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginTop: '0px' }}>
            <LabelMedium style={{ marginBottom: '16px' }}>Form Intro Title</LabelMedium>
            <Input
              value={form_module_intro_heading}
              onChange={e => set_form_module_intro_heading(e.target.value)}
              placeholder=""
              clearOnEscape
              focus
            />
          </div>
          <div style={{ marginTop: '8px' }}>
            <LabelMedium style={{ marginBottom: '16px' }}>Form Intro Description</LabelMedium>
            <Input
              value={form_module_intro_description}
              onChange={e => set_form_module_intro_description(e.target.value)}
              placeholder=""
              clearOnEscape
              focus
            />
          </div>
          <div style={{ marginTop: '8px' }}>
            <LabelMedium style={{ marginBottom: '16px' }}>Form Intro Button Text</LabelMedium>
            <Input
              value={form_module_intro_button_text}
              onChange={e => set_form_module_intro_button_text(e.target.value)}
              placeholder=""
              clearOnEscape
              focus
            />
          </div>
          <div style={{ marginTop: '8px' }}>
            <LabelMedium style={{ marginBottom: '16px' }}>Form Intro Step Button Text</LabelMedium>
            <Input
              value={form_module_step_button_text}
              onChange={e => set_form_module_step_button_text(e.target.value)}
              placeholder=""
              clearOnEscape
              focus
            />
          </div>
          <div style={{ marginTop: '8px' }}>
            <LabelMedium style={{ marginBottom: '16px' }}>Form Intro Final Button Text</LabelMedium>
            <Input
              value={form_module_last_step_button_text}
              onChange={e => set_form_module_last_step_button_text(e.target.value)}
              placeholder=""
              clearOnEscape
              focus
            />
          </div>
          <div style={{ marginTop: '8px' }}>
            <LabelMedium style={{ marginBottom: '16px' }}>Form Intro Scoring Script</LabelMedium>
            <Input
              value={form_module_scoring_script}
              onChange={e => set_form_module_scoring_script(e.target.value)}
              placeholder=""
              clearOnEscape
              focus
            />
          </div>
          <div style={{ marginTop: '16px' }}>
            <Checkbox
              checked={form_module_is_first_form_meta}
              onChange={e => set_form_module_is_first_form_meta(!form_module_is_first_form_meta)}
              labelPlacement={LABEL_PLACEMENT.right}
            >
              <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Is first form metadata collection?</ParagraphSmall>
            </Checkbox>
          </div>
          <div style={{ marginTop: '8px' }}>
            <ImageUploadCXL
              title={'Intro Image'}
              titleHelpText={'Select the image for intro logo'}
              previewUrls={preview_image || image}
              isMultiple={false}
              onDrop={(acceptedFiles, rejectedFiles) => {
                setUploadedFile(acceptedFiles);
                setPreview(URL.createObjectURL(acceptedFiles[0]));
              }}
              onImageDelete={() => {
                setUploadedFile(null);
                setPreview(null);
                setImage(null)
              }}
              showFullBleedSettings={false}
              imageSize={slideImageGuideline.size}
              imageSizeGuidelineURL={slideImageGuideline.url}
            ></ImageUploadCXL>
          </div>
        </div>
      </Cell>
    )
  }, [
    id,
    componentType, form_module_intro_heading,
    form_module_intro_description,
    form_module_intro_button_text,
    form_module_step_button_text,
    form_module_last_step_button_text,
    form_module_scoring_script,
    form_module_is_first_form_meta,
    preview_image,
    image
  ])

  const SharepointFormModuleFilterFields = React.useMemo(() => {
    const canShow = id && componentType === 'CardStackModule';
    if (!canShow) return null;
    return (
      <Cell
        span={[1, 12]}
        overrides={{
          Cell: {
            style: () => ({
              paddingLeft: '0px !important',
            }),
          },
        }}
      >
        <LabelMedium style={{ marginBottom: '16px' }}>Filter Controls</LabelMedium>
        <Checkbox
          checked={disable_filter_button}
          onChange={e => set_disable_filter_button(!disable_filter_button)}
          labelPlacement={LABEL_PLACEMENT.right}
        >
          <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Disable Filter Button</ParagraphSmall>
        </Checkbox>
      </Cell>
    )
  }, [
    id, componentType,
    disable_filter_button
  ])

  return (
    <React.Fragment>
      {/* <Button onClick={() => setIsEditOpen(true)}>Add Category</Button> */}
      <Modal
        isOpen={isImportModalOpen}
        onClose={() => {
          setIsImportModalOpen(false);
        }}
        returnFocus={false}
      >
        <ModalHeader>{importModalTitle}</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          {importFile && <LabelMedium>File Selected {importFile.name}</LabelMedium>}
          <FileUploader
            onDrop={(acceptedFiles, rejectedFiles) => {
              setImportFile(acceptedFiles[0])

            }}
          />
          {componentType == 'CardStackModule' && <>
            <ParagraphSmall style={{ marginTop: 24 }}>Import from Google Document</ParagraphSmall>
            <LabelMedium style={{ marginTop: 8, marginBottom: 8 }}>Document Id</LabelMedium>
            <Input
              value={documentId}
              onChange={e => setDocumentId(e.target.value)}
              placeholder="Document Id"
              clearOnEscape
            />
            <LabelMedium style={{ marginTop: 16, marginBottom: 8 }}>Separator(Regex)</LabelMedium>
            <Input
              value={separatorRegex}
              onChange={e => setSeparatorRegex(e.target.value)}
              placeholder="Regex for cards separator"
              clearOnEscape
            />
          </>}
        </ModalBody>
        <ModalFooter>
          <ModalButton
            isLoading={importLoading}
            disabled={!importFile && !documentId}
            onClick={onClickImport}
          >
            {'Import'}
          </ModalButton>
        </ModalFooter>
      </Modal>
      <ModalStateContainer>
        {({ isConfirmationOpen, toggleConfirm }) => (
          <>
            <Modal
              overrides={{
                Backdrop: {
                  style: ({ $theme }) => ({
                    backdropFilter: $theme.modalBackDropFilter,
                  }),
                }
              }}
              isOpen={isOpen}
              autoFocus={true}
              focusLock={true}
              returnFocus={false}
              onClose={() => {
                onClose();
                setIsOpen(false);
              }}
            >
              <ModalHeader>{isOpen && getModalTitle()}</ModalHeader>
              {!isDelete && <ModalBody style={{ flex: '1 1 0' }}>
                <Grid
                  gridGaps={[12, 12, 24]}
                  overrides={{
                    Grid: {
                      style: ({ $theme }) => outerGridOverride,
                    },
                  }}
                >
                  <Cell
                    span={[1, 12]}
                    overrides={{
                      Cell: {
                        style: () => ({
                          paddingLeft: '0px !important',
                        }),
                      },
                    }}
                  >
                    <LabelMedium style={{ marginBottom: '16px' }}>Title</LabelMedium>
                    <Input
                      value={title}
                      onChange={e => setTitle(e.target.value)}
                      placeholder="Add title for component"
                      clearOnEscape
                      focus
                    />
                  </Cell>
                  {SharepointFormModuleFields}
                  {/* {componentType === 'SharePointFormModule' &&
                    <Cell
                      span={[1, 12]}
                      overrides={{
                        Cell: {
                          style: () => ({
                            paddingLeft: '0px !important',
                          }),
                        },
                      }}
                    >
                      <LabelMedium style={{ marginBottom: '16px' }}>Form Type</LabelMedium>
                      <div style={{ marginLeft: '16px' }}>
                        <Checkbox
                          checked={isFormSingle}
                          onChange={e => setIsFormSingle(!isFormSingle)}
                          labelPlacement={LABEL_PLACEMENT.right}
                        >
                          <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Is Single Form?</ParagraphSmall>
                        </Checkbox>
                      </div>
                      <div style={{ marginLeft: '16px' }}>
                        <Checkbox
                          checked={isSteppedForm}
                          onChange={e => setIsSteppedForm(!isSteppedForm)}
                          labelPlacement={LABEL_PLACEMENT.right}
                        >
                          <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Is Stepped Form?</ParagraphSmall>
                        </Checkbox>
                      </div>
                    </Cell>
                  } */}
                  {/* {componentType === 'SharePointFormModule' &&
                    <Cell
                      span={[1, 12]}
                      overrides={{
                        Cell: {
                          style: () => ({
                            paddingLeft: '0px !important',
                          }),
                        },
                      }}
                    >
                      <LabelMedium style={{ marginBottom: '16px' }}>Form Report Type</LabelMedium>
                      <div style={{ marginLeft: '16px' }}>
                        <Checkbox
                          checked={form_report_type === 'list'}
                          onChange={e => onChangeFormReportType('list')}
                          labelPlacement={LABEL_PLACEMENT.right}
                        >
                          <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>List</ParagraphSmall>
                        </Checkbox>
                      </div>
                      <div style={{ marginLeft: '16px' }}>
                        <Checkbox
                          checked={form_report_type === 'table'}
                          onChange={e => onChangeFormReportType('table')}
                          labelPlacement={LABEL_PLACEMENT.right}
                        >
                          <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Table</ParagraphSmall>
                        </Checkbox>
                      </div>
                    </Cell>
                  } */}
                  {id && <Cell
                    span={[1, 12]}
                    overrides={{
                      Cell: {
                        style: () => ({
                          paddingLeft: '0px !important',
                        }),
                      },
                    }}
                  >
                    <LabelMedium style={{ marginBottom: '16px' }}>Accessibility Control</LabelMedium>
                    <div style={{ display: 'flex' }}>
                      <Checkbox
                        checked={publicS}
                        onChange={e => setPublic(!publicS)}
                        labelPlacement={LABEL_PLACEMENT.right}
                      >
                        <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Public</ParagraphSmall>
                      </Checkbox>
                      <div style={{ marginLeft: '16px' }}>
                        <Checkbox
                          checked={published}
                          onChange={e => setPublished(!published)}
                          labelPlacement={LABEL_PLACEMENT.right}
                        >
                          <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Published</ParagraphSmall>
                        </Checkbox>
                      </div>
                      {is_api_settings_on && id && <div style={{ marginLeft: '16px' }}>
                        <Checkbox
                          checked={has_data_source_connected}
                          onChange={e => set_has_data_source_connected(!has_data_source_connected)}
                          labelPlacement={LABEL_PLACEMENT.right}
                        >
                          <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Has data source</ParagraphSmall>
                        </Checkbox>
                      </div>}
                    </div>
                  </Cell>}

                  {is_api_settings_on && id && has_data_source_connected && <Cell
                    span={[1, 12]}
                    overrides={{
                      Cell: {
                        style: () => ({
                          paddingLeft: '0px !important',
                        }),
                      },
                    }}
                  >
                    <LabelMedium style={{ marginBottom: '16px' }}>Api Path</LabelMedium>
                    <Select
                      options={paths}
                      value={paths.filter(el => el.id == api_path_id)}
                      labelKey="name"
                      valueKey="id"
                      placeholder=''
                      onChange={({ value }) => set_api_path_id(value[0].id)}
                      overrides={{
                        Popover: {
                          props: {
                            overrides: {
                              Body: { style: { zIndex: 3 } },
                            },
                          },
                        },
                      }}
                    />
                  </Cell>}



                  {/* {componentType === 'CardwareFormModule' && id && <Cell
                    span={[1, 12]}
                    overrides={{
                      Cell: {
                        style: () => ({
                          paddingLeft: '0px !important',
                        }),
                      },
                    }}
                  >
                    <hr style={{ height: '2px', margin: 0, marginTop: '16px', marginBottom: '16px', background: '#E2E2E2' }} />
                    <LabelMedium style={{ marginBottom: '16px' }}>Advance Cardware Form Module Setup</LabelMedium>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ marginTop: '0px' }}>
                        <LabelMedium style={{ marginBottom: '16px' }}>Form Intro Title</LabelMedium>
                        <Input
                          value={form_module_intro_heading}
                          onChange={e => set_form_module_intro_heading(e.target.value)}
                          placeholder=""
                          clearOnEscape
                          focus
                        />
                      </div>
                      <div style={{ marginTop: '8px' }}>
                        <LabelMedium style={{ marginBottom: '16px' }}>Form Intro Description</LabelMedium>
                        <Input
                          value={form_module_intro_description}
                          onChange={e => set_form_module_intro_description(e.target.value)}
                          placeholder=""
                          clearOnEscape
                          focus
                        />
                      </div>
                      <div style={{ marginTop: '8px' }}>
                        <LabelMedium style={{ marginBottom: '16px' }}>Form Intro Button Text</LabelMedium>
                        <Input
                          value={form_module_intro_button_text}
                          onChange={e => set_form_module_intro_button_text(e.target.value)}
                          placeholder=""
                          clearOnEscape
                          focus
                        />
                      </div>
                      <div style={{ marginTop: '8px' }}>
                        <LabelMedium style={{ marginBottom: '16px' }}>Form Intro Step Button Text</LabelMedium>
                        <Input
                          value={form_module_step_button_text}
                          onChange={e => set_form_module_step_button_text(e.target.value)}
                          placeholder=""
                          clearOnEscape
                          focus
                        />
                      </div>
                      <div style={{ marginTop: '8px' }}>
                        <LabelMedium style={{ marginBottom: '16px' }}>Form Intro Final Button Text</LabelMedium>
                        <Input
                          value={form_module_last_step_button_text}
                          onChange={e => set_form_module_last_step_button_text(e.target.value)}
                          placeholder=""
                          clearOnEscape
                          focus
                        />
                      </div>
                      <div style={{ marginTop: '8px' }}>
                        <LabelMedium style={{ marginBottom: '16px' }}>Form Intro Scoring Script</LabelMedium>
                        <Input
                          value={form_module_scoring_script}
                          onChange={e => set_form_module_scoring_script(e.target.value)}
                          placeholder=""
                          clearOnEscape
                          focus
                        />
                      </div>
                      <div style={{ marginTop: '16px' }}>
                        <Checkbox
                          checked={form_module_is_first_form_meta}
                          onChange={e => set_form_module_is_first_form_meta(!form_module_is_first_form_meta)}
                          labelPlacement={LABEL_PLACEMENT.right}
                        >
                          <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Is first form metadata collection?</ParagraphSmall>
                        </Checkbox>
                      </div>
                      <div style={{ marginTop: '8px' }}>
                        <ImageUploadCXL
                          title={'Intro Image'}
                          titleHelpText={'Select the image for intro logo'}
                          previewUrls={preview_image || image}
                          isMultiple={false}
                          onDrop={(acceptedFiles, rejectedFiles) => {
                            setUploadedFile(acceptedFiles);
                            setPreview(URL.createObjectURL(acceptedFiles[0]));
                          }}
                          onImageDelete={() => {
                            setUploadedFile(null);
                            setPreview(null);
                            setImage(null)
                          }}
                          showFullBleedSettings={false}
                          imageSize={slideImageGuideline.size}
                          imageSizeGuidelineURL={slideImageGuideline.url}
                        ></ImageUploadCXL>
                      </div>
                    </div>
                  </Cell>} */}
                  {CardwareFormModuleFields}
                  {SharepointFormModuleFilterFields}
                  {
                    id && (
                      <Cell
                        span={[1, 12]}
                        overrides={{
                          Cell: {
                            style: () => ({
                              paddingLeft: '0px !important',
                            }),
                          },
                        }}
                      >
                        <LabelMedium style={{ marginBottom: '16px' }}>Style Override</LabelMedium>
                        <BaseUiTextArea
                          value={style_override || ''}
                          onChange={(v) => set_style_override(v)}
                          placeholder={'Write style override here!'}
                        />
                      </Cell>
                    )
                  }
                  {/* {
                    id && componentType === 'CardStackModule' && <Cell
                      span={[1, 12]}
                      overrides={{
                        Cell: {
                          style: () => ({
                            paddingLeft: '0px !important',
                          }),
                        },
                      }}
                    >
                      <LabelMedium style={{ marginBottom: '16px' }}>Filter Controls</LabelMedium>
                      <Checkbox
                        checked={disable_filter_button}
                        onChange={e => set_disable_filter_button(!disable_filter_button)}
                        labelPlacement={LABEL_PLACEMENT.right}
                      >
                        <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Disable Filter Button</ParagraphSmall>
                      </Checkbox>
                    </Cell>
                  } */}
                </Grid>
              </ModalBody>}
              <ModalFooter>
                {canShowImport && <ModalButton
                  onClick={onImportComponentClick}
                  kind="tertiary"
                >
                  {'Import'}
                </ModalButton>}
                {id && <ModalButton
                  onClick={() => {
                    !isLoading && toggleConfirm(true);
                  }}
                  // isLoading={isLoading}
                  disabled={isLoading}
                >
                  {archived ? 'Un Archive' : 'Archive'}
                </ModalButton>}
                {!isDelete && <ModalButton
                  onClick={onSave}
                  disabled={!title || titlesLength.home_component > 32}
                  isLoading={isLoading}
                >
                  {id ? 'Update' : 'Add'}
                </ModalButton>}
              </ModalFooter>
            </Modal>
            <Modal
              onClose={() => toggleConfirm(false)}
              isOpen={isConfirmationOpen}
              overrides={{
                Root: {
                  style: {
                    zIndex: 4,
                  }
                }
              }}
            >
              <ModalHeader>Confirm</ModalHeader>
              <ModalBody>Are you sure you want to {archived ? 'un archive' : 'archive'} this component?</ModalBody>
              <ModalFooter>
                <ModalButton
                  kind="tertiary"
                  onClick={() => toggleConfirm(false)}
                >
                  No
                </ModalButton>
                <ModalButton
                  onClick={() => {
                    onSave({ archived: !archived })
                  }}
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  Yes
                </ModalButton>
              </ModalFooter>
            </Modal>
          </>
        )}
      </ModalStateContainer>
      <Modal
        overrides={{
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          },
          Dialog: {
            style: {
              // width: '80vw',
              // height: '80vh',
              display: 'flex',
              flexDirection: 'column',
            },
          },
        }}
        isOpen={isOpenComponentTypeModal}
        autoFocus={true}
        focusLock={true}
        onClose={() => {
          onClose();
          setIsOpen(false);
          setIsOpenComponentTypeModal(false);
        }}
        closeable={true}
      >
        {/* <ModalHeader>Add new Component</ModalHeader> */}
        <ModalBody style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '12px' }}>
            {/* <ModalButton
              disabled={isLoading}
              onClick={() => {
                onClose();
                setIsOpen(false);
                setIsOpenComponentTypeModal(false);
              }}
              kind={ButtonKind.tertiary}
            >x</ModalButton> */}
          </div>
          <LabelMedium style={{ marginBottom: '16px' }}>Select component type</LabelMedium>
          <Select
            options={FILTERED_COMPONENT_TYPES}
            value={COMPONENTS_TYPE.filter(el => el.id == componentType)}
            labelKey="label"
            valueKey="id"
            placeholder=''
            onChange={({ value }) => {
              value.length > 0 ? setComponentType(value[0].id) : setComponentType(null)
            }}
          // getOptionLabel={getSelectComponentOptionLabel}
          />
          {/* </Cell>

          </Grid> */}
        </ModalBody>
        <ModalFooter>
          <ModalButton
            disabled={!componentType}
            onClick={() => {
              setIsOpenComponentTypeModal(false);
              setIsOpen(true);
            }}
            isLoading={isLoading}
          >
            Add Component
          </ModalButton>
        </ModalFooter>
      </Modal>

      {/* <Modal
        overrides={{
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          },
          Dialog: {
            style: {
              width: '80vw',
              height: '80vh',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto'
            },
          },
        }}
        isOpen={isOpenComponentTypeModal}
        autoFocus={true}
        focusLock={true}
        onClose={() => {
          onClose();
          setIsOpen(false);
          setIsOpenComponentTypeModal(false);
        }}
      >
        <ModalHeader>Add new Component</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          {renderComponentTypes()}
        </ModalBody>
        <ModalFooter>
          <ModalButton
            disabled={!componentType}
            onClick={() => {
              setIsOpenComponentTypeModal(false);
              setIsOpen(true);
            }}
            isLoading={isLoading}
          >
            Okay
          </ModalButton>
        </ModalFooter>
      </Modal> */}
    </React.Fragment>
  );
};
